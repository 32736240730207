<template>
  <div>
    <div class="container-fluid pb-7 pt-7">
      <div class="row">
        <div class="col-lg-9">
          <div class="row mb-10">
            <div class="col-lg-6">
              <label
                for="destinationOrigin"
                class="text-primary font-12 font-weight-6"
              >Pilih Kota Keberangkatan</label>
              <v-select class="search-icon" name="destinationOrigin"  v-validate="'required'"  v-model="selectDepart" :options="destination" placeholder="Kota Keberangkatan"></v-select>
               <span class="eror text-danger font-10">{{errors.first('destinationOrigin')}}</span>
            </div>
             <div class="col-lg-6">
              <label
                for="destinationDepart"
                class="text-primary font-12 font-weight-6"
              >Pilih Kota Tujuan</label>
              <v-select class="search-icon" name="destinationDepart"  v-validate="'required'"  :options="destination" v-model="selectReturn" placeholder="Kota Tujuan"></v-select>
               <span class="eror text-danger font-10" >{{errors.first('destinationDepart')}}</span>
            </div>
            
          </div>
          <div class="row mb-20 tr-all"  >
            <div class="col-lg-4 pos-relative"  >
              <label for="Passanger" class="text-primary font-12 font-weight-6">Jumlah Penumpang <span class="text-danger">*</span> </label>
              <div  class="icon-passenger"   @click="showDrop = true">
              <input
                type="text"
                name="passangerJumlah"
                placeholder="Jumlah Penumpang"
                v-model="passenger"
                v-validate="'required'"
                class="input-same-select pos-relative  "
                 disabled
              />
               <span class="eror text-danger font-10" >{{errors.first('passangerJumlah')}}</span>
              </div>
              <template v-if="showDrop"> 
                   <div class="dropdown-passanger tr-all shadow ">
                  <div class="form-group pad-20">
                        <b-form-group label-cols="8" label-cols-lg="5"  class="font-weight-6 text-primary" label-size="sm" label="Dewasa" label-for="input-sm ">
                            
                              <b-form-select v-model="adult"  name="adult" placeholder="please select "   v-validate="'required'" :value="adultObjs.value"  :options="adultObjs"></b-form-select>

                             <span class="eror text-danger font-10" >{{errors.first('adult')}}</span>
                        </b-form-group>
                        
                         <b-form-group label-cols="8" label-cols-lg="5"  class="font-weight-6 text-primary" label-size="sm" label="Anak-anak" label-for="input-sm ">
                           
                             <b-form-select v-model="child"  name="child" placeholder="please select "   v-validate="'required'" :value="childObjs.value"  :options="childObjs"></b-form-select>
                        </b-form-group>
                         <b-form-group label-cols="8" label-cols-lg="5"  class="font-weight-6 text-primary" label-size="sm" label="Bayi" label-for="input-sm ">
                          <b-form-select v-model="infant"  name="child" placeholder="please select "   v-validate="'required'" :value="infantObjs.value"  :options="infantObjs"></b-form-select>
                        </b-form-group> 
                         <span @click.prevent="submitchild()" class="cursor-pointer font-weight-6 font-14 text-white d-inline-block text-center pad-10 border-radius-5 width-100 bg-primary text-right">
                            Done 
                        </span>
                  </div>
              </div>
              </template>
             
            </div>
            <div class="" v-bind:class="{'col-md-8' : enabled == false , 'col-md-4' : enabled == true }">
                 <label for="anyDes" class="text-primary font-12 font-weight-6">Tanggal Keberangkatan</label>
                 <div class="icon-date">
                <flat-pickr
                  v-model="departdate"
                  :config="config" 
                  v-validate="'required'"
                  class="input-same-select"
                  placeholder="Pilih Tanggal"
                  name="datedepart"
                ></flat-pickr>
                <span class="eror text-danger font-10" >{{errors.first('datedepart')}}</span>
                 </div>
            </div>
              <div class="col-lg-4" v-show="enabled">
                 <label for="anyDes" class="text-primary font-12 font-weight-6">Tanggal Pulang</label>
                <div class="icon-date">
                <flat-pickr
                  v-model="returndate"
                  :config="config"
                 
                  class="input-same-select"
                  placeholder="Pilih Tanggal"
                  name="datereturn"
                ></flat-pickr>
                <span class="eror text-danger font-10" >{{errors.first('datereturn')}}</span>
                </div>
            </div>
             
          </div>
        </div>
        <div class="col-lg-3">
          <div class="pos-relative tr-all open-up" >
            <a
              href="#"
              @click.prevent="submitSearch()"
              class="btn bg-warning advance-search text-white font-weight-6 pt-3 pb-3 width-100 font-16"
            >
              <span class>
                Cari Penerbangan
                <i class="fa fa-search"></i>
              </span>
            </a>
         
              <div class="advance-search mb-10 width-100  "  >
         
                  <label for="anyDes" class="text-primary float-left font-16 font-weight-6">Tanggal Pulang</label>
                    <div class="ml-3 mt-1 float-left">
                  <switches v-model="enabled" theme="bootstrap"  color="success" ></switches>
                  </div> 
              </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script> 
 
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css"; 
import Switches from 'vue-switches';
import * as moment from 'moment'
import data from './airport.json'

export default {
    components:{  
        flatPickr,
        Switches
      
    },
  data() {
    return {
       airportData: data,
        enabled:false,
        showDrop:false,
        selectDepart:null,
        selectReturn:null,
        returndate:null,
      destination: [],
      passenger:'Dewasa: 1 Anak: 0 Bayi: 0',
      adult: 1,
      adultObjs:[
        {
          value:1, text: '1 Orang'
        },
          {
          value:2, text: '2 Orang'
        },
         {
          value:3, text: '3 Orang'
        },
         {
          value:4, text: '4 Orang'
        },
         {
          value:5, text: '5 Orang'
          },
         {
          value:6, text: '6 Orang'
        },
         {
          value:7, text: '7 Orang'
        },
         {
          value:8, text: '8 Orang'
        },
         {
          value:9, text: '9 Orang'
        } 
      ],
      child: 0,
       childObjs:[
        {
          value:0, text: 'Tidak Terisi'
        },
        {
          value:1, text: '1 Orang'
        },
          {
          value:2, text: '2 Orang'
        },
         {
          value:3, text: '3 Orang'
        },
         {
          value:4, text: '4 Orang'
        },
         {
          value:5, text: '5 Orang'
          },
         {
          value:6, text: '6 Orang'
        } 
      ],
        infant: 0,
       infantObjs:[
        {
          value:0, text: 'Tidak Terisi'
        },
        {
          value:1, text: '1 Orang'
        },
          {
          value:2, text: '2 Orang'
        },
         {
          value:3, text: '3 Orang'
        },
         {
          value:4, text: '4 Orang'
        },
         {
          value:5, text: '5 Orang'
          },
         {
          value:6, text: '6 Orang'
        } 
      ], 
       departArray:[],
       returnArray:[],
      departdate:null,
       date: new Date(),
      // Get more form https://chmln.github.io/flatpickr/options/
      config: {
        mode: "single",
        minDate: "today",
        tokendata:null,
        failedMessage:false,
        dateFormat: "d-M-Y"
      }
      
    };
  },
  watch:{
    
    departdate(val){
    this.config.minDate = val
    },
    selectDepart(val){   
      this.departArray = val.split(",") 
    },
    selectReturn(val){
      this.returnArray = val.split(",") 
      
    }
  },
  mounted(){ 
    this.getdataObj(this.airportData)
  },
  
  methods: {
      getdataObj(newVal){
        newVal.data.airports.forEach(element => {
          this.destination.push(element.airport_code + ' , ' + element.city + ' , ' + element.country)
        });
      },
      submitchild(){
      

          this.passenger = ' Dewasa: '+ this.adult + ' Anak: ' + this.child + ' Bayi: ' + this.infant 

         if(this.child == null){
            this.passenger = ' Dewasa: '+ this.adult + ' Anak: ' + '0' + ' Bayi: ' + this.infant 
         } 
         if(this.infant == null){
            this.passenger = ' Dewasa: '+ this.adult + ' Anak: ' + '0' + ' Bayi: ' + '0' 
         }
         
          
          this.showDrop = false
          this.failedMessage = true
         
      
       
      }, 
      submitSearch(){
           this.$validator.validateAll().then(valid => {
     
        if (valid) {
            if(this.$store.state.userdata !== null){
               this.tokendata = this.$store.state.userdata.token
             }else{
                this.tokendata = null
             }
            
                setTimeout(() => {
                  if(this.enabled){
                    location.assign('https://ticket.realtravel.co.id/product/flight/search?redirect=https%3A%2F%2Fticket.realtravel.co.id%2Fproduct%2Fflight&search=flight&origin='+this.departArray[0].trim()+'&origin_label='+this.departArray[1].trim()+'%2C+'+this.departArray[2].trim()+'+%28'+this.departArray[0].trim()+'%29&destination='+this.returnArray[0].trim()+'&destination_label='+this.returnArray[1].trim()+'%2C+'+this.returnArray[2].trim()+'+%28'+this.returnArray[0].trim()+'+%29&depart='+moment(this.departdate).format('D-mM-Y')+'&return='+moment(this.returndate).format('D-mM-Y')+'&adult='+this.adult+'&child='+ this.child +'&infant='+this.infant+'&hct='+this.tokendata+'')
                  }else{
                    location.assign('https://ticket.realtravel.co.id/product/flight/search?redirect=https%3A%2F%2Fticket.realtravel.co.id%2Fproduct%2Fflight&search=flight&origin='+this.departArray[0].trim()+'&origin_label='+this.departArray[1].trim()+'%2C+'+this.departArray[2].trim()+'+%28'+this.departArray[0].trim()+'%29&destination='+this.returnArray[0].trim()+'&destination_label='+this.returnArray[1].trim()+'%2C+'+this.returnArray[2].trim()+'+%28'+this.returnArray[0].trim()+'+%29&depart='+moment(this.departdate).format('D-mM-Y')+'&return=&adult='+this.adult+'&child='+ this.child +'&infant='+this.infant+'&hct='+this.tokendata+'')
                  }
               }, 500); 
            
            //  console.log(this.$store.state.userdata.token)
         
         
        } else {
          this.failedMessage = true
        }
      })
        
      },

  },
};
</script>