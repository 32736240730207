<template>
  <div>
    <div class="container-fluid pb-7 pt-7">
      <div class="row">
        <div class="col-lg-9">
          <div class="row mb-10">
            <div class="col-lg-8 pos-relative">
              <label
                for="destination"
                class="text-primary font-12 font-weight-6"
              >City, destination, or hotel name</label> 
                <div class="icon-find">
                    <input type="text" v-validate="'required'" name="HotelDestination"    placeholder="Nama Hotel, tempat wisata atau nama kota" v-model="searchotel" class="input-same-select pos-relative">
                       <span class="eror text-danger font-10" >{{errors.first('HotelDestination')}}</span>
               </div>
               <div class="list-Hotel-finder shadow bg-white" v-show="changeWord">
                 <div class="item-find cursor-pointer" :key="index" v-for="(items, index) in destination" >
                   <span @click="submitSearch(items)" class="font-14 item--find">{{ items.displayName }}</span>
                 </div>
               </div>
            
            </div>  

            <div class="col-lg-4 pos-relative"  >
              <label for="filter" class="text-primary font-12 font-weight-6">Jumlah Passenger</label>
              <div  class="icon-passenger"   @click="showDrop = true">
              <input
                type="text"
                 v-validate="'required'"
                placeholder="Jumlah Passanger"
                v-model="passenger"
                class="input-same-select pos-relative  "
                 disabled
               
               
              />
              </div>
             
                   <div class="dropdown-passanger tr-all shadow " v-show="showDrop">
                  <div class="form-group pad-20">
                        <b-form-group label-cols="8" label-cols-lg="5"  class="font-weight-6 text-primary" label-size="sm" label="Adult" label-for="input-sm ">
                             <b-form-select v-model="adult"  name="Adult" placeholder="please select "   v-validate="'required'" :value="adultObjs.value"  :options="adultObjs"></b-form-select>
                              <span class="eror text-danger font-10" >{{errors.first('Adult')}}</span>
                        </b-form-group>
                         <b-form-group label-cols="8" label-cols-lg="5"  name="Child"  class="font-weight-6 text-primary"  label-size="sm" label="Child" label-for="input-sm ">
                              <b-form-select v-model="child"  name="child" placeholder="please select "   v-validate="'required'" :value="childObjs.value"  :options="childObjs"></b-form-select>
                           
                        </b-form-group>
                       
                         <span @click.prevent="submitchild()" class="cursor-pointer font-weight-6 font-14 text-white d-inline-block text-center pad-10 border-radius-5 width-100 bg-primary text-right">
                            Done 
                        </span>
                  </div>
              </div>
             
             
            </div>
          </div>
          <div class="row mb-20 tr-all"  >
               <div class="col-lg-4 pos-relative"  >
              <label for="filter" class="text-primary font-12 font-weight-6">Jumlah Kamar</label>
              <div  class="icon-date" >
              <input
                type="number"
                 v-validate="'required'"
                
                v-model="room"
                name="kamar"
                class="input-same-select pos-relative  "
             
              />
                <span class="eror text-danger font-10" >{{errors.first('kamar')}}</span>
              <label class="label-jamaah" > Kamar </label>
              </div>
               </div>
            <div class="col-lg-4">
                 <label for="anyDes" class="text-primary font-12 font-weight-6">Check In</label>
                 <div class="icon-date">
                <flat-pickr
                  v-model="checkin"
                  :config="config"
                  class="input-same-select"
                  placeholder="Tanggal checkin"
                  name="ChekIn"
                  v-validate="'required'"
                ></flat-pickr>
                 <span class="eror text-danger font-10" >{{errors.first('ChekIn')}}</span>
                 </div>
            </div>
              <div class="col-lg-4"  >
                 <label for="anyDes" class="text-primary font-12 font-weight-6">Check Out</label>
                <div class="icon-date">
                <flat-pickr
                  v-model="checkout"
                  :config="config"
                  class="input-same-select"
                  placeholder="Tanggal checkout"
                  name="CheckOut"
                  v-validate="'required'"
                ></flat-pickr>
                 <span class="eror text-danger font-10" >{{errors.first('CheckOut')}}</span>
                </div>
            </div>
             
          </div>
        </div>
        <div class="col-lg-3">
          <div class="pos-relative tr-all open-up" >
            <a
              href="#"
              @click.prevent="submitForm()"
              class="btn bg-warning advance-search -mt-0 text-white font-weight-6 pt-4 pb-4 width-100 font-16"
            >
              <span class>
                Cari Hotel
                <i class="fa fa-search"></i>
              </span>
            </a> 
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script> 
 
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css"; 
// import Switches from 'vue-switches';
import moment from 'moment'

export default {
    components:{  
        flatPickr,
        // Switches
      
    },
  data() {
    return {
        searchotel:null,
        enabled:true,
        showDrop:false,
        changeWord:false,
        checkout:null,
        wording:null,
      destination: [],
      passenger:'adult: 1 child: 0',
       adult: 1,
      adultObjs:[
        {
          value:1, text: '1 Orang'
        },
          {
          value:2, text: '2 Orang'
        },
         {
          value:3, text: '3 Orang'
        },
         {
          value:4, text: '4 Orang'
        },
         {
          value:5, text: '5 Orang'
          },
         {
          value:6, text: '6 Orang'
        },
         {
          value:7, text: '7 Orang'
        },
         {
          value:8, text: '8 Orang'
        },
         {
          value:9, text: '9 Orang'
        },
         {
          value:10, text: '10 Orang'
        } 
      ],
      child: 0,
       childObjs:[
        {
          value:0, text: 'Tidak Terisi'
        },
        {
          value:1, text: '1 Orang'
        },
          {
          value:2, text: '2 Orang'
        },
         {
          value:3, text: '3 Orang'
        },
         {
          value:4, text: '4 Orang'
        },
         {
          value:5, text: '5 Orang'
          },
         {
          value:6, text: '6 Orang'
        },
         {
          value:7, text: '7 Orang'
        },
         {
          value:8, text: '8 Orang'
        },
         {
          value:9, text: '9 Orang'
        },
         {
          value:10, text: '10 Orang'
        } 
      ],
      room:'1', 
      month: ["Januari", "February", "Maret"],
      sort: ["Termurah", "Termahal", "Terbaik"],
      category: ["Haji", "Haji Plus Umrah"],
      anydes: ["Umroh Start Jakarta", "Umroh Start Bandung"],
      maxBudget: null,
       failedMessage: false,
       fixedsubstr: null,
       valHotelItemGet:null,
      checkin:null,
       date: new Date(), 
      config: {
        mode: "single",
        minDate: "today",
        dateFormat: "D d-M-Y"
      }
      
    };
  },
  mounted(){ 
  },
  watch: {

  checkin(val){
   this.config.minDate = val
  },
    searchotel(val){
      this.searchotel = val
      if(val == ''){
        this.changeWord = false
      }else{
        this.changeWord = true
      }
        this.getData();
    },
     
  },
  methods: { 
    submitchild(){
         
      
         if(this.child == null){
            this.child = '0'
         } 
         this.passenger = ' adult: '+  this.adult + ' child: ' + this.child  
          
                this.showDrop = false
          this.failedMessage = true
    },
    getData(){
        let param = new FormData();
         param.append('api_key', '2222')
          param.append('format', '')
         param.append('ws', 'hotel')
          param.append('call', 'ws_srKeyword')
           param.append('word', this.searchotel) 
      this.axios.post('https://apitiket.realtravel.co.id/ws',param,{
       
         headers: new Headers({
           'accept': 'application/json', 
          'Content-Type': 'multipart/form-data, text/plain',   
        })
      })
      .then( response => {
        let res = response.data 
        this.destination = res.result
        
      })
      .catch( error =>{
        error
      });
    }, 
    submitSearch(val){
     this.valHotelItemGet = val
      this.searchotel = val.displayName
      setTimeout(() => {
          this.changeWord = false
      }, 200); 
    },
    submitForm(){
      this.$validator.validateAll().then(valid => {
        if (valid) {
           this.fixedsubstr = this.valHotelItemGet.displayName.split(' ').join('+')
           if(this.$store.state.userdata !== null){
               this.tokendata = this.$store.state.userdata.token
             }else{
                this.tokendata = null
             }
            //  console.log(this.tokendata)
             setTimeout(() => {
               location.assign('https://ticket.realtravel.co.id/product/hotel/search?search=hotel&lat='+this.valHotelItemGet.centerPoint.lat+'&lng='+this.valHotelItemGet.centerPoint.lng+'&location='+this.fixedsubstr.split(',').join('%2C')+'&checkin='+moment(this.checkin).format('D-mM-Y')+'&checkout='+moment(this.checkout).format('D-mM-Y')+'&room='+this.room+'&adult='+this.adult+'&child='+this.child+'&hct='+this.tokendata+'')
             }, 500);
          
        } else {
          this.failedMessage = true
        }
      })
    } 
  },
};
</script>