<template>
  <div>
    <div class="container-fluid pb-7 pt-7">
      <div class="row">
        <div class="col-lg-9">
          <div class="row mb-15 tr-all">
            <div class="col-lg-6">
              <label for="anyDes" class="text-primary font-12 font-weight-6">Tanggal Keberangkatan</label>
              <div class="icon-date">
                <flat-pickr
                  v-model="daterange"
                  name="Date"
                  :config="config"
                  v-validate="'required'"
                  class="input-same-select"
                  placeholder="Select date" 
                ></flat-pickr>
                   <span class="eror text-danger font-10" >{{errors.first('Date')}}</span>
              </div>
            </div>
            <!-- <div class="col-lg-6">
              <label for="anyDes" class="text-primary font-12 font-weight-6">Tanggal Pulang</label>
              <div class="icon-date">
                <flat-pickr
                  v-model="dateplan"
                  :config="config"
                  class="input-same-select"
                  placeholder="Select date"
                  name="datesingle"
                ></flat-pickr>
              </div>
            </div> -->
            <div class="col-lg-6">
              <label for="destination" class="text-primary font-12 font-weight-6">Keberangkatan</label>
              <v-select class="search-icon" :options="destinationCustomArray" 
                      label="text" 
                      v-model="destinationCustom"
                      name="destination"
                      v-validate="'required'"
               placeholder="Select Origin Destination"></v-select>
               <span class="eror text-danger font-10" >{{errors.first('destination')}}</span>
            </div>
          </div>
          <div class="row mb-20"> 
             <div class="col-lg-6">
              <label for="longTrip" class="text-primary font-12 font-weight-6">Lama Perjalanan</label>
              <v-select class="user-icon" :options="durationArr" 
                      label="text" 
                      v-model="durationstreet"
                      name="longTrip"
                      v-validate="'required'"
               placeholder="Select Long Trip"></v-select>
               <span class="eror text-danger font-10" >{{errors.first('longTrip')}}</span>
            </div>
            <div class="col-lg-6 pos-relative">
              <label for="filter" class="text-primary font-12 font-weight-6">Jumlah Jamaah</label>
              <div class="icon-passenger">
                <input
                  type="number"
                  name="JumalahJamaah"
                  v-model="jumlahJamaah" 
                  v-validate="'required'"
                  class="input-same-select pos-relative"
                />
                <label class="label-jamaah" > Jama'ah </label>
                   <span class="eror text-danger font-10" >{{errors.first('JumalahJamaah')}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <!-- with modal -->

          <!-- <div class="pos-relative tr-all open-up">
            <a
              href="#"
              v-b-modal.modal-1
              class="btn bg-warning advance-search d-inline width-100 mt-20 text-white font-weight-6 pt-4 pb-4 width-200 font-16"
            >
              <span class>
                Buat Paket Umroh
                <strong>
                  <i class="fa fa-plus"></i>
                </strong>
              </span>
            </a>
          </div> -->

          <!-- end -->
             <div class="pos-relative tr-all open-up">
            <a
              href="#" 
              @click.prevent="submit()"
              class="btn bg-warning advance-search d-inline width-100 mt-20 text-white font-weight-6 pt-4 pb-4 width-200 font-16"
            >
              <span class>
                Buat Paket Umroh
                <strong>
                  <i class="fa fa-plus"></i>
                </strong>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- <b-modal id="modal-1" hide-footer="true" size="lg" no-close-on-backdrop title="Detail Lokasi">
      <div class="row">
        <div class="col-md-12 mb-20">
          <label
            for="destination"
            class="text-primary font-14 mb-15 font-weight-6"
          >Pilih kota asal keberangkatan Anda!</label>
          <v-select class="search-icon" :options="destination" placeholder="Pilih Kota Asal"></v-select>
        </div>
        <div class="col-md-12 mb-20">
          <label
            for="destination"
            class="text-primary font-14 mb-15 font-weight-6"
          >Pilih Kota yang akan Anda kunjungi</label>
          <b-form-group label>
            <b-form-checkbox-group
              button-variant="success"
              id="checkbox-group-1"
              v-model="selected"
              :options="options"
              name="flavour-1"
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
        <div class="col-md-6 mb-20">
          <label
            for="destination"
            class="text-primary font-14 mb-15 font-weight-6"
          >Pilih kota mana Anda akan mendarat!</label>
          <v-select class="search-icon" :options="destination" placeholder="Pilih kota  mendarat!"></v-select>
        </div>
        <div class="col-md-6 mb-20">
          <label
            for="destination"
            class="text-primary font-14 mb-15 font-weight-6"
          >Pilih kota dari mana Anda akan pulang!</label>
          <v-select class="search-icon" :options="destination" placeholder="Pilih kota pulang!"></v-select>
        </div>
        <div class="col-md-6 mb-20">
          <label
            for="destination"
            class="text-primary font-14 mb-15 font-weight-6"
          >Berapa hari di Makkah?</label>
          <input
            type="number"
            placeholder="Berapa Hari Anda di Makkah"
            v-model="passenger"
            class="input-same-select pos-relative"
          />
        </div>
        <div class="col-md-6 mb-20">
          <label
            for="destination"
            class="text-primary font-14 mb-15 font-weight-6"
          >Berapa hari di Madinah?</label>
          <input
            type="number"
            placeholder="Berapa Hari Anda di Makkah"
            v-model="passenger"
            class="input-same-select pos-relative"
          />
        </div>
        <div class="col-md-12 mb-10">
          <label
            for="destination"
            class="text-primary font-14 mb-15 font-weight-6"
          >Pilih urutan kota Anda menginap!</label>

          <v-select
            class="before_1 mb-20"
            :options="destination_number"
            placeholder="Pilih kota penginapan"
          ></v-select>
          <v-select
            class="before_2"
            :options="destination_number"
            placeholder="Pilih kota penginapan"
          ></v-select>
        </div>
        <div class="col-md-12 text-center pb-9">
          <a
            href="#"
            class="btn bg-warning advance-search text-white font-weight-6 pt-2 mt-4 pb-2 width-100 font-16"
          >
            <span>Submit</span>
          </a>
        </div>
      </div>
    </b-modal> -->
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// import Switches from 'vue-switches';
// import moment from 'moment'

export default {
  components: {
    flatPickr
    // Switches
  },
  data() {
    return {
      selected: [],
      destinationCustom:null,
      jumlahJamaah: 1 ,
      options: [
        { text: "Makkah", value: "1" },
        { text: "Madinah", value: "2" }
      ],
      durationstreet:null,
      durationArr: ["9 Hari", "10 Hari", "11 Hari", "12 Hari", "13 Hari", "14 Hari", "15 Hari", "16 Hari","17 Hari"],
      destinationCustomArray:[
    
        { 
        id: 0, 
         text: "Dalam Negeri" 
         },
        {
          id: 1,
          text: "Luar Negeri"
        }, 
      ],
      enabled: true,
      showDrop: false,
      dateplan: null,
      jumlahkamar: null,
      destination: ["Bandung", "Jakarta", "Makasar"],
      destination_number: ["Makah", "Madinah"],

      passenger: null,
      month: ["Januari", "February", "Maret"],
      sort: ["Termurah", "Termahal", "Terbaik"],
      category: ["Haji", "Haji Plus Umrah"],
      anydes: ["Umroh Start Jakarta", "Umroh Start Bandung"],
      maxBudget: null,
      failedMessage:false,
      daterange: null,
      date: new Date(),
      // Get more form https://chmln.github.io/flatpickr/options/
      config: {
        mode: "single",
        minDate: "today",
        dateFormat: "D d-M-Y"
      }
    };
  },
 
  methods: {
    submit(){
       this.$validator.validateAll().then(valid => {
        if (valid) {
          location.assign('https://umroh.realtravel.co.id/index.php/custom/submit');
        } else {
          this.failedMessage = true;
        }
      });
    }
  }
};
</script>