<template>
  <div>
    <template v-if="shoDashes">
       
  
    <div class="container-fluid pb-7 pt-7">
      <div class="row">
        <div class="col-lg-9">
          <div class="row mb-10">
            <div class="col-lg-6">
              <label
                for="destination"
                class="text-primary font-12 font-weight-6"
              >Picking Up Location</label>
              <v-select class="search-icon" :options="destination" placeholder="Picking Up Location"></v-select>
            </div>
             <div class="col-lg-6">
              <label
                for="destination"
                class="text-primary font-12 font-weight-6"
              >Dropping Off Location</label>
              <v-select class="search-icon" :options="destination" placeholder="Picking Up Location"></v-select>
            </div> 
          </div>
          <div class="row mb-20 tr-all"  >
            <div class="col-lg-6">
                 <label for="anyDes" class="text-primary font-12 font-weight-6">Picking Up Date and Time</label>
                 <div class="icon-date">
                <flat-pickr
                  v-model="daterange"
                  :config="config"
                  class="input-same-select"
                  placeholder="Select date"
                  name="daterange"
                ></flat-pickr>
                 </div>
            </div>
              <div class="col-lg-6" v-show="enabled">
                 <label for="anyDes" class="text-primary font-12 font-weight-6">Dropping Off Date and Time</label>
                <div class="icon-date">
                <flat-pickr
                  v-model="dateplan"
                  :config="config"
                  class="input-same-select"
                  placeholder="Select date"
                  name="datesingle"
                ></flat-pickr>
                </div>
            </div>
             
          </div>
        </div>
        <div class="col-lg-3">
          <div class="pos-relative tr-all open-up" >
            <a
              href="#"
              class="btn bg-warning advance-search text-white font-weight-6 pt-3 pb-3 width-100 font-16"
            >
              <span class>
                Cari Bus
                <i class="fa fa-search"></i>
              </span>
            </a>
         
              <!-- <div class="advance-search mb-10 width-100  "  > 
                  <label for="anyDes" class="text-primary float-left font-16 font-weight-6">Return Date</label>
                    <div class="ml-3 mt-1 float-left">
                  <switches v-model="enabled" theme="bootstrap"  color="success" ></switches>
                  </div> 
              </div> -->
            
          </div>
        </div>
      </div>
    </div>
      </template>
      <template v-else>
        <div>
          <span class="text-center font-24 font-weight-6 text-center d-block w-100 mt-10 text-primary">Under Development</span>
        </div>
      </template>
  </div>
</template>
<script> 
 
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css"; 
// import Switches from 'vue-switches';
// import moment from 'moment'

export default {
    components:{  
        flatPickr,
        // Switches
      
    },
  data() {
    return {
        enabled:true,
        shoDashes:false,
        showDrop:false,
        dateplan:null,
      destination: ["(BDO) Bandung", "(SMA) Semarang", "(PLA) Polimandar"],
      passenger:null,
      month: ["Januari", "February", "Maret"],
      sort: ["Termurah", "Termahal", "Terbaik"],
      category: ["Haji", "Haji Plus Umrah"],
      anydes: ["Umroh Start Jakarta", "Umroh Start Bandung"],
      maxBudget: null,
       
      daterange:null,
       date: new Date(),
      // Get more form https://chmln.github.io/flatpickr/options/
      config: {
        mode: "single",
         enableTime: true,
        minDate: "today",
        dateFormat: "d-M-Y H:i"
      }
      
    };
  },
  methods: {
      
  },
};
</script>