<template>
  <div>
    <div class="container-fluid pb-7 pt-7">
      <div class="row">
        <div class="col-lg-9">
          <div class="row mb-10">
            <div class="col-lg-6">
              <label for="destination" class="text-primary font-12 font-weight-6">Nama Tour</label>
              <div class="icon-find">
                <input
                  type="text"
                  placeholder="Cari Nama Tour"
                  v-model="nametour"
                  class="input-same-select pos-relative"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <label for="destination" class="text-primary font-12 font-weight-6">Jenis Tour</label>
              <v-select
                class="filter-icon"
                :options="sort"
                label="text"
                v-model="tourtype"
                name="TypeTour"
                placeholder="Pilih Jenis Tour"
              ></v-select>
              <span class="eror text-danger font-10">{{errors.first('TypeTour')}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="pos-relative tr-all">
            <a
              href="#"
              @click.prevent="submit()"
              class="btn bg-warning advance-search text-white font-weight-6 pt-2 mt-4 pb-2 width-100 font-16"
            >
              <span class>
                Cari Sekarang
                <i class="fa fa-search"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import flatPickr from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
// import Switches from "vue-switches";
// import moment from 'moment'
// import cookie from "js-cookie";
export default {
  components: {
    // flatPickr,
    // Switches
  },
  data() {
    return {
      enabled: true,
      showDrop: false,
      dateplan: null,
      tourtype: "",
      nametour: "",
      sort: [
        {
          id: 0,
          text: "domestik"
        },
        {
          id: 1,
          text: "international"
        },
        {
          id: 2,
          text: "leisure"
        },
        {
          id: 3,
          text: "studi-banding"
        }
      ],
      maxBudget: null,
      tourNull: "",
      daterange: null,
      date: new Date(),
      // Get more form https://chmln.github.io/flatpickr/options/
      config: {
        mode: "single",
        minDate: "today",
        dateFormat: "d-M-Y"
      }
    };
  },
  mounted() {
    localStorage.removeItem("response-tour");
  },
  methods: {
    submit() {
      let param = new FormData();
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "tour");
      param.append("type", "tour");
      param.append("keyword", this.nametour);
      if (this.tourtype !== "") {
        param.append("category", this.tourtype.id);
      }

      param.append("call", "list_product");

      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain"
          })
        })
        .then(response => {
          let res = response.data;
          localStorage.setItem("response-tour", JSON.stringify(res));
          // this.destination = res.result
          setTimeout(() => {
            this.$router.push("/list-tour");
          }, 500);
        })
        .catch(error => {
          error;
        });
    }

    // if(this.nametour == null){
    //     this.nametour = ''
    // }
    // if(this.tourtype != null){
    //  location.assign('https://liburanku.id/?name_tour='+this.nametour+'&tour_search=1&tourtax%5Btour_phys%5D='+this.tourtype.text+'&lang=');
    // }else{
    //   location.assign('https://liburanku.id/?name_tour=&tour_search=1&tourtax%5Btour_phys%5D=0&lang=')
  }
};
</script>