<template>
  <div>
    <div class="container-fluid pb-7 pt-7">
      <div class="row">
        <div class="col-lg-9">
          <div class="row mb-10">
            <div class="col-lg-4">
              <label
                for="destination"
                class="text-primary font-12 font-weight-6"
              >Pilih Bulan Keberangkatan</label>
              <v-select class="date-icon" :options="month" name="month" v-validate="'required'"  v-model="montModel" placeholder="Pilih Bulan" size="sm"></v-select>
               <span class="eror text-danger font-10" >{{errors.first('month')}}</span>
              <span></span>
            </div>            
            <div class="col-lg-4">
              <label for="destination" class="text-primary font-12 font-weight-6">Keberangkatan Dari</label>
              <v-select class="user-icon"               
                name="fromDepart"  
                :options="sort"  
                label="text"   
                v-model="fromDepart"
                placeholder="Pilih Cabang"></v-select>
               <span class="eror text-danger font-10" >{{errors.first('fromDepart')}}</span>
            </div>
            <div class="col-lg-4">
              <label for="destination" class="text-primary font-12 font-weight-6">Lama Perjalanan</label>
              <v-select class="date-icon" name="duration" v-model="durationModel" v-validate="'required'" :options="duration" placeholder="Lama Perjalanan"></v-select>
               <span class="eror text-danger font-10" >{{errors.first('duration')}}</span>
            </div>
            <!-- <div class="col-lg-4">
              <label for="destination" class="text-primary font-12 font-weight-6">Kisaran Harga</label>
              <v-select class="filter-icon"  v-validate="'required'" 
               
                name="priceRange"  
                 :options="sort"  
                  label="text"   
                v-model="fromDepart"  
                placeholder="Select Range Price"></v-select>
               <span class="eror text-danger font-10" >{{errors.first('priceRange')}}</span>
            </div> -->
          </div>
           
        </div>
        <div class="col-lg-3">
          <div class="pos-relative tr-all  ">
            <a
              href="#"
              @click.prevent="submit()"
              class="btn bg-warning advance-search text-white font-weight-6 pt-2 mt-4 pb-2 width-100 font-16"
            >
              <span>
                Cari Sekarang
                <i class="fa fa-search"></i>
              </span>
            </a>

            <!-- <div class="advance-search mb-10 width-100">
              <label for="anyDes" class="text-primary float-left font-16 font-weight-6">Return Date</label>
              <div class="ml-3 mt-1 float-left">
                <switches v-model="enabled" theme="bootstrap" color="success"></switches>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import flatPickr from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
// import Switches from "vue-switches";
// import moment from 'moment'

import * as moment from 'moment';
export default {
  
  data() {
    return {
      enabled: true,
      showDrop: false,
      dateplan: null,
      montModel:'',
      durationModel:null,
      fromDepart:null,

      // destination: ["(BDO) Bandung", "(SMA) Semarang", "(PLA) Polimandar"],
      passenger: null,
      month: ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"],
      // sort: ["Lebih Dari 25 Juta", "Kurang Dari 25 Juta"],
      sort: [
        { "id": 0, text: 'Cabang Jakarta' },
        { "id": 1, text: 'Cabang Makassar'},
        { "id": 2, text: 'Cabang Maros' },
        { "id": 3, text: 'Cabang Bandung'}, 
        { "id": 4, text: 'Cabang Pekanbaru' },
        { "id": 5, text: 'Cabang Banten'},
        { "id": 6, text: 'Cabang Jepara' },
        { "id": 7, text: 'Cabang Palembang'},
        { "id": 8, text: 'Cabang Lampung' },
        { "id": 9, text: 'Cabang Polewali Mandar'},
      ],
       duration: ["9 Hari", "10 Hari", "11 Hari", "12 Hari", "13 Hari", "14 Hari", "15 Hari", "16 Hari","17 Hari"],
      // anydes: ["Umroh Start Jakarta", "Umroh Start Bandung"],
      maxBudget: null,
      failedMessage : false,
      daterange: null,
      date: new Date(),
      config: {
        mode: "single",
        minDate: "today",
        dateFormat: "d-M-Y"
      }
    };
  },
   computed: { 
    selectedIp() {
      return this.selected ? this.selected['ip_address'] : null
    }   
  },
  mounted() {
    localStorage.removeItem("response-umroh");
  },
  methods: { 
    submit(){ 
      let param = new FormData();
      let dm = moment().month(this.montModel).format('MM');
      let fdepart = (this.fromDepart != null) ? this.fromDepart.text : '';
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "umroh");
      param.append("keyword", ''); 
      param.append("call", "list_umroh"); 
      param.append("month_depart", (this.montModel == '') ? '' : dm); 
      param.append("from_depart", fdepart); 
      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain"
          })
        })
        .then(response => {
          let res = response.data;
          if (res.rest_no != 0) {            
            localStorage.setItem("response-umroh", JSON.stringify(res));
            setTimeout(() => {
              this.$router.push("/paket-umroh");
            }, 500);
          } else {            
            localStorage.setItem("response-umroh", JSON.stringify(res));
            setTimeout(() => {
              this.$router.push("/paket-umroh");
            }, 500);
          }
        })
        .catch(error => {
          error;
      });
    } 
  }
};
</script>