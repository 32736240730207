<template>
  <div class="home">
    <!-- <slick ref="slick" class="slickHome   bg-extnd  slider-home hide-mobile" @afterChange="handleAfterChange" v-if="slideArr.length"   :options="slickOptions"> 
      <div class="slick-content-home" v-for="(slide, index) in slideArr" :key="index">
        <div
          class="sub-content-slider "
          :style="{'background-image': 'url(' +  slide.file + ')'}"> 
        </div>
      </div> 
    </slick>-->
    <slick ref="slick" class="slickHome hide-mobile" :options="slickOptions">
      <div class="slick-content-home">
        <div
          class="sub-content-slider"
          :style="{'background-image': 'url(' + require('../assets/images/background/ex-1.jpg') + ')'}"
        >
          <div class="content-text">
            <!-- <span
                    class="d-block font-40 text-white opensans font-weight-6"
                  >SELAMAT DATANG DI TRAVEL UMRAH TERBESAR</span>
                  <span
                    class="d-block font-20 text-white opensans"
            >Wujudkan perjalanan ibadah umrah anda bersama kami</span>-->
          </div>
        </div>
      </div>
      <div class="slick-content-home">
        <div
          class="sub-content-slider"
          :style="{'background-image': 'url(' + require('../assets/images/background/ex-2.jpg') + ')'}"
        >
          <div class="content-text">
            <h1
              class="d-block font-40 text-white opensans font-weight-6"
            >SELAMAT DATANG DI REAL TRAVEL</h1>
            <h2
              class="d-block font-20 text-white opensans"
            >Travel Umroh dan Haji Terbaik, Berpengalaman dan Terpercaya Sejak 1996</h2>
          </div>
        </div>
      </div>
    </slick>

    <div class="container-standar bg-white pl-10 pr-10 pb-9">
      <section class="mb-10 pos-relative-anigram">
        <div class="card-statistic finding-card pb-5 shadow-md border-radius-5">
          <b-tabs content-class="mt-3" fill>
            <b-tab>
              <template v-slot:title>
                <div class="finding-tab">
                  <img src="@/assets/images/icon/umroh.png" alt />
                  <span>Umroh</span>
                </div>
              </template>
              <div class="container-findder">
                <umroh-search></umroh-search>
              </div>
            </b-tab>

            <b-tab>
              <template v-slot:title>
                <div class="finding-tab">
                  <img src="@/assets/images/icon/tour.png" alt />
                  <span>Tour</span>
                </div>
              </template>
              <div class="container-findder">
                <tour-search></tour-search>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <div class="finding-tab">
                  <img src="@/assets/images/icon/pesawat.png" alt />
                  <span>Pesawat</span>
                </div>
              </template>
              <div class="container-findder">
                <flight-search></flight-search>
              </div>
            </b-tab>

            <b-tab>
              <template v-slot:title>
                <div class="finding-tab">
                  <img src="@/assets/images/icon/bus.png" alt />
                  <span>Bus</span>
                </div>
              </template>
              <div class="container-findder">
                <bus-search></bus-search>
              </div>
            </b-tab>

            <b-tab>
              <template v-slot:title>
                <div class="finding-tab">
                  <img src="@/assets/images/icon/hotel.png" alt />
                  <span>Hotel</span>
                </div>
              </template>
              <div class="container-findder">
                <hotel-search></hotel-search>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <div class="finding-tab">
                  <img src="@/assets/images/icon/custom-umroh.png" alt />
                  <span class="text-center">Custom Umroh</span>
                </div>
              </template>
              <div class="container-findder">
                <UmrohCusSearch></UmrohCusSearch>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </section>
      <section class="pb-res-10 cont-mar mt-res-70">
        <div class="row hide-mobile">
          <div class="col-md-4 text-center">
            <img
              src="@/assets/images/icon/tepat-waktu.png"
              alt="Keberangkatan-Tepat-Waktu"
              class="margin-auto"
              width="225"
              height="185"
            />
            <h3 class="d-block margin-auto font-20 text-dark mb-10 font-weight-6">Keberangkatan Tepat Waktu</h3>
            <span
              class="d-block margin-auto font-14 text-grey"
            >Jadwal berangkat yang tepat waktu salah satu perioritas utama kami dalam menyelenggarakan umroh, haji khusus dan perjalanan wisata.</span>
            <!--<button @click.prevent="custom()" type="button" class="btn btn-light">Klik Disini</button>-->
          </div>
          <div class="col-md-4 text-center">
            <img
              src="@/assets/images/icon/kepercayaan.png"
              alt="Berpengalaman-Terbaik-Terpercaya"
              class="margin-auto"
              width="225"
              height="185"
            />
            <h3
              class="d-block margin-auto font-20 text-dark mb-10 font-weight-6"
            >Berpengalaman, Terbaik & Terpercaya</h3>
            <span
              class="d-block margin-auto font-14 text-grey"
            >Beridiri dan melayani sejak 1996 hingga hari ini, mengutamakan kualitas dengan prinsip amanah profesional dan inovatif</span>
            <!--<button @click.prevent="custom()" type="button" class="btn btn-light">Klik Disini</button>-->
          </div>
          <div class="col-md-4 text-center">
            <img
              src="@/assets/images/icon/izin.png"
              alt="Memiliki-Izin-Penyelenggaraan"
              class="margin-auto"
              width="225"
              height="185"
            />
            <h3
              class="d-block margin-auto font-20 text-dark mb-10 font-weight-6"
            >Memiliki Izin Penyelenggaraan</h3>
            <span
              class="d-block margin-auto font-14 text-grey"
            >Pakem Tours memiliki SK Resemi dari Kementerian Agama sebagai Penyelenggara Haji Khusus dan Izin sebagai Penyelenggara Umroh.</span>
            <!--<button @click.prevent="custom()" type="button" class="btn btn-light">Klik Disini</button>-->
          </div>
        </div>
      </section>

      <section class="mt-10">
        <div class="row hide-mobile">
          <div class="col-md-4 text-center">
            <img
              src="@/assets/images/icon/umrah.png"
              alt="Layanan-Kuota-Haji"
              class="margin-auto"
            />
            <h3 class="d-block margin-auto font-20 text-dark mb-10 font-weight-6">Layanan Kuota Haji</h3>
            <span
              class="d-block margin-auto font-14 text-grey"
            >Ingin mengambil kuota haji resmi kemenag tapi DP belum cukup ? tidak masalah. Kami menyediakan layanan bayar setengah harga dengan mencicil sisa DP sampai dengan 2 tahun.</span>
            <!--<button @click.prevent="custom()" type="button" class="btn btn-light">Klik Disini</button>-->
          </div>
          <div class="col-md-4 text-center">
            <img
              src="@/assets/images/icon/tabungan-umrah.png"
              alt="Layanan-Tabungan-Umroh"
              class="margin-auto"
            />
            <h3
              class="d-block margin-auto font-20 text-dark mb-10 font-weight-6"
            >Layanan Tabungan Umroh</h3>
            <span
              class="d-block margin-auto font-14 text-grey"
            >Ingin berangkat umroh namun uang belum cukup? Tidak masalah. Kami menyediakan layanan Tabungan Umroh terpercaya dengan promo menarik di dalamnya.</span>
            <!--<button @click.prevent="custom()" type="button" class="btn btn-light">Klik Disini</button>-->
          </div>
          <div class="col-md-4 text-center">
            <img
              src="@/assets/images/icon/cicilan-umrah.png"
              alt="Fitur-Cicilan-Tanpa-Bunga"
              class="margin-auto"
            />
            <h3
              class="d-block margin-auto font-20 text-dark mb-10 font-weight-6"
            >Fitur Cicilan Tanpa Bunga</h3>
            <span
              class="d-block margin-auto font-14 text-grey"
            >Anda dapat memesan paket umroh yang Anda inginkan dengan DP Minimal, dilanjutkan dengan pelunasan pembayaran yang harus diselesaikan Sebulan sebelum keberangkatan.</span>
            <!--<button @click.prevent="custom()" type="button" class="btn btn-light">Klik Disini</button>-->
          </div>
        </div>
      </section>

      <section class="mb-90 mb-res-20 mt-10 mt-res-40">
        <div class="row">
          <div class="col-md-12 mb-40 text-center">
            <h2 class="d-block text-primary font-28 font-weight-7 roboto">PAKET HAJI</h2>
            <h3 class="d-block text-grey font-16">Paket Haji Langsung dan Haji Khusus</h3>
          </div>
          <div class="col-6" :key="index" v-for="(items, index) in hajiCont">
            <div class="container-haji">
              <div class="sub">
              
                <template v-if="items.images == 0">
                  <img src="https://i0.wp.com/pakemtours.co.id/wp-content/uploads/2019/08/hajj-icon-700x466.jpg" :alt="items.slug">
                </template>
                 <template v-else >
                   <div  >
                    <img v-bind:src="chesen(items)" :alt="items.slug" />
                  </div>
                </template> 
              </div>
              <div class="content">
                <div class="font-14 font-weight-5 text-primary title-haji">{{ items.nama }}</div>
                <span class="d-block hide-mobile">
                  <i class="fa fa-star text-warning"></i>
                  <i class="fa fa-star text-warning"></i>
                  <i class="fa fa-star text-warning"></i>
                  <i class="fa fa-star text-warning"></i>
                  <i class="fa fa-star text-warning"></i>
                </span>
                <p class="roboto mt-2 font-14 hide-mobile">{{ contentDesc(items.display_deskripsi) }}</p>
                <a
                @click="routesUP(items.slug)"
                  class="d-block width-70 w-res-tab-100 text-res-center cursor-pointer bg-primary mt-4 font-14 pad-10 text-white border-radius-5"
                >
                 
                    Lihat <span class="hide-mobile">Selengkapnya</span>
                    <i class="fa fa-arrow-right pos-relative top-2"></i>
                  
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="container-fluid mb-50 mb-res-10 bg-customise-content pt-10 pb-1">
      <section class="mb-80 pt-15">
        <div class="container">
          <div class="row">
            <div class="col col-md-12 text-center mb-40 tiitle-ex">
              <h2 class="d-block text-white font-28 font-weight-7 roboto">PAKET UMROH</h2>
              <h3 class="d-block text-white font-20">Paket Umroh Terbaru dan Banyak Promo</h3>
            </div>
            <div class="row">
              <package-umroh></package-umroh>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="container-fluid">
       <div class="col-md-12 mt-10 mb-10 text-center">
        <h2 class="d-block text-primary font-28 font-weight-7 roboto">PAKET TOUR</h2>
        <h3 class="d-block text-grey font-20">Pilihan Destinasi Wisata Terbaik</h3>
      </div>
      <div class="row">
      <beritaList></beritaList>
      </div>
                
    </div>
 
  </div>
</template>

<script>
// @ is an alias to /src
import stripHtml from "string-strip-html";
import Slick from "vue-slick";
import TourSearch from "./components/tour-search";
import FlightSearch from "./components/flight-search";
import UmrohSearch from "./components/umroh-search";
import BusSearch from "./components/bus-search";
import HotelSearch from "./components/hotel-search";
import UmrohCusSearch from "./components/custom-umroh";
import PackageUmroh from "./components/package-umroh";
import beritaList from "./components/berita-list";
export default {
  name: "home",
  components: {
    Slick,
    TourSearch,
    FlightSearch,
    UmrohSearch,
    HotelSearch,
    BusSearch,
    UmrohCusSearch,
    PackageUmroh,
    beritaList
  },
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: "Data Bantuan Sosial" },
        { value: "1", text: "Data Kependuduk" },
        { value: "2", text: "Data Kesehatan" },
        { value: "3", text: "Data Bantuan Sosial" },
        { value: "4", text: "Data Keuangan" }
      ],
      selected1: null,
      options1: [{ value: null, text: "Tahun 2018" }],
      slideArr: [],
      slickOptions: {
        slidesToShow: 1,
 
        infinite: false,
        autoplay: true,

        autoplaySpeed: 5000,
        dots: false,
        arrows: false

        // Any other options that can be got from plugin documentation
      },
      hajiCont: [],
      slickEvent: {
        slidesToShow: 1,
        infinite: false,
        autoplay: true,

        autoplaySpeed: 5000,
        dots: false,
        arrows: false,
        centerMode: true
        // Any other options that can be got from plugin documentation
      },
      slideOff: true
    };
  },
  mounted() {
    this.$store.commit("SET_BG", false);
    this.getslideData();
  },
  methods: {
    handleAfterChange(slick, currentSlide) {
      // console.log( currentSlide, slick);
      if (currentSlide.currentSlide == 0) {
        this.slideOff = true;
      } else {
        this.slideOff = false;
      }
    },
    contentDesc(NewVal) {
   return stripHtml(NewVal).substring(0, 190) + "...";
    },
     chesen(val){
      return val.images[0].file
      // return val[0]
    },
    routesUP(NewVal){
      this.$router.push('/haji/'+NewVal)
    },
    getslideData() {
      // this.axios
      //   .get("https://api.realtravel.co.id/content/slideshow")
      //   .then(response => {
      //     this.slideArr = response.data.response.data;
      //     //  console.log(this.slideArr)
      //   })
      //   .catch(e => {
      //     // console.log(e);
      //     this.err.push(e);
      //   });
      let param = new FormData();
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "tour");
      param.append("type", "haji");
      param.append("call", "list_product");

      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain"
          })
        })
        .then(response => {
          let res = response.data.data;
          // this.destination = res.result
          // console.log(res);
          this.hajiCont = res;
        
        })
        .catch(error => {
          error;
        });
    },   
  custom(){
       this.$router.push("/paket-umroh");
    },
  }
};
</script>
